import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 全局共享
    uname: null,
    token: null,
    avatar: null,
    company_id: null,
    // cityValue:null
  },
  getters: {},
  mutations: {
    // 修改cityValue并将token存入localStorage
    // updateCityValue(state,value){
    //   state.cityValue=value;
    //   localStorage.setItem('cityValue', value)
    // },

    // 修改company_id，并将token存入localStorage
    updateCompanyId(state, company_id) {
      state.company_id = company_id;
      localStorage.setItem('company_id', company_id)
    },

    //修改token，并将token存入localStorage
    updateToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token)
    },
    //修改uname，并将uname存入localStorage
    updateUname(state, uname) {
      state.uname = uname;
      localStorage.setItem('uname', uname)
    },
    //修改avatar，并将avatar存入localStorage
    updateAvatar(state, avatar) {
      state.avatar = avatar;
      localStorage.setItem('avatar', avatar)
    }
  },
  actions: {},
  modules: {}
})