<template>
  <!-- 首页 -->
  <div class="box">
    <!-- 首页导航栏内容 -->
    <div class="index_1">
      <div class="menu">
        <div class="logo">
          <div @click="changeLogo">
            <img src="../assets/images/userIndex/logo.png" />
            <!-- <span style="cursor: pointer">美小满</span> -->
          </div>
        </div>
        <div class="menu_content">
          <span :class="[this.$route.meta.line_1 ? 'click_line' : '']"><router-link to="/">首页</router-link> </span><span
            :class="[this.$route.meta.line_3 ? 'click_line' : '']"><router-link
              to="/mxm/work">商务合作</router-link></span><span
            :class="[this.$route.meta.line_2 ? 'click_line' : '']"><router-link
              to="/mxm/solution">解决方案</router-link></span><span
            :class="[this.$route.meta.line_4 ? 'click_line' : '']"><router-link
              to="/mxm/aboutUS">关于我们</router-link></span>
          <div @click="ifWidths">
            <span to="" style="color: #fff">登录</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 路由内容 -->
    <router-view></router-view>
    <!-- 首页底部内容 -->
    <div class="content_6">
      <!-- f1 -->
      <div>
        <div>
          <p>产品与服务</p>
          <p>了解产品</p>
          <p>解决方案</p>
        </div>
        <div>
          <p>关于我们</p>
          <p>公司简介</p>
        </div>
        <div>
          <p>联系我们</p>
          <div>
            <span>咨询电话:</span>
            <span>139 1199 1541</span>
          </div>
          <div>
            <span>商务合作</span>
            <span>meihaodeshiqing@163.com</span>
          </div>
          <div>
            <span>意见反馈</span>
            <span>meihaodeshiqing@163.com</span>
          </div>
        </div>
        <div>
          <p>友情链接</p>
          <p @click="redirectUrl('https://mall.jd.com/index-1000425650.html')">京东商城</p>
          <p @click="redirectUrl('https://mall.mhdsq.com')">美小满商城</p>
        </div>
        <div>
          <p>关注我们</p>
        </div>
      </div>
      <!-- f2 -->
      <div>
        <p><span>使用条款</span> &nbsp; | &nbsp; <span>隐私政策</span></p>
        <p>
          Copyright © 2020-2023 美好的事情（北京） 京ICP备15002531号-12
          京公网安备 42018502005160号
        </p>
      </div>
    </div>
    <!-- 返回顶部按钮 -->
    <el-backtop target="" style="color: #EB5C20"></el-backtop>
  </div>
</template>

<script>
export default {
  data() {
    return {
      widths: null,
    };
  },
  mounted() {
    // 获取当前屏幕尺寸
    this.widths = window.screen.width;
    // //防止页面一直后退，可回退一级
    // history.pushState(null, null, document.URL);
    // window.addEventListener("popstate", function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  methods: {
    // 判断当前视频的屏幕尺寸进行跳转登录页操作
    ifWidths() {
      //console.log(this.widths);
      // 点击时再次获取一次当前屏幕尺寸
      let width_a = window.screen.width;
      this.widths = width_a;
      // 如果大于等于1000像素则允许跳转登录
      // if (this.widths >= 1000) {
      // this.$router.push("/mxm/login");
      window.location.href = 'https://customer.mhdsq.com/';

      // } else {
      //   this.$notify({
      //     title: "警告",
      //     message: "当前设备为移动端，无法登录体验！",
      //     type: "warning",
      //   });
      // }

    },
    // logo点击事件
    changeLogo() {
      // this.$router.push("/");
      window.location.href = 'https://customer.mhdsq.com/';
    },
    //跳转链接
    redirectUrl(url = '') {
      window.location.href = url;
    }
  },
};
</script>

<style lang="less" scoped>
.click_line {
  border-bottom: 4px solid #EB5C20;
}

// pc
@media screen and (min-width: 1000px) {
  .menu_content {
    width: 50%;
    padding-top: 0.75rem;

    >span:first-child {
      width: 3.25rem;
      text-align: center;
    }
  }

  .menu {
    padding-top: 1.875rem;
    position: relative;
  }

  .logo {
    margin-bottom: 0.3125rem;
    height: 3.125rem;

    >div {
      display: flex;
      position: absolute;
      top: 22%;

      >span {
        line-height: 3.125rem;
        margin-left: 0.625rem;
        font-weight: 600;
      }
    }
  }

  .content_6 {
    background-color: rgba(51, 51, 51, 1);

    >div {
      width: 95%;
      margin: auto;
      color: #fff;
    }

    >div:first-child {
      display: flex;
      justify-content: space-around;
      padding-top: 2.1875rem;
      padding-bottom: 2.1875rem;
      border-bottom: 1px solid #aaa;

      >div {
        >p:first-child {
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 1.25rem;
        }
      }

      >div:first-child {
        >p:nth-child(2) {
          margin-bottom: 1.25rem;
          font-size: 0.8125rem;
          cursor: pointer;
          text-align: center;
        }

        >p:last-child {
          cursor: pointer;
          font-size: 0.8125rem;
          text-align: center;
        }

        >p:nth-child(2):hover {
          text-decoration: underline;
        }

        >p:nth-child(3):hover {
          text-decoration: underline;
        }
      }

      >div:nth-child(2) {
        >p:last-child {
          text-align: center;
          font-size: 0.8125rem;
          cursor: pointer;
        }

        >p:last-child:hover {
          text-decoration: underline;
        }
      }

      >div:nth-child(3) {
        >div {
          font-size: 0.8125rem;
          padding-left: 0.3125rem;

          >span {
            display: block;
            cursor: pointer;
          }

          >span:hover {
            text-decoration: underline;
          }

          >span:first-child {
            margin-bottom: 0.625rem;
          }
        }

        >div:nth-child(3) {
          margin: 1.875rem 0;
        }
      }

      >div:nth-child(4) {
        >p:nth-child(2) {
          font-size: 0.8125rem;
          text-align: center;
          margin-bottom: 1.25rem;
          cursor: pointer;
        }

        >p:nth-child(3) {
          font-size: 0.8125rem;
          text-align: center;
          cursor: pointer;
        }

        >p:nth-child(2):hover {
          text-decoration: underline;
        }

        >p:nth-child(3):hover {
          text-decoration: underline;
        }
      }
    }

    >div:last-child {
      padding: 1.875rem 0;

      >p {
        width: 52%;
        margin: auto;
        font-size: 0.8125rem;
        text-align: center;
        cursor: pointer;
      }

      >p:first-child {
        margin-bottom: 1.25rem;
      }

      >p>span:hover {
        text-decoration: underline;
      }
    }
  }
}

// 平板
@media screen and (max-width: 1000px) and (min-width: 768px) {
  .menu_content {
    width: 50%;
    padding-top: 0.75rem;
  }

  .logo {
    margin-bottom: 0.3125rem;
    height: 3.125rem;

    >div {
      display: flex;
      position: absolute;
      top: 22%;

      >span {
        line-height: 3.125rem;
        margin-left: 0.625rem;
        font-weight: 600;
      }
    }
  }

  .menu {
    padding-top: 1.875rem;
    position: relative;
  }

  .content_6 {
    background-color: rgba(51, 51, 51, 1);

    >div {
      width: 95%;
      margin: auto;
      color: #fff;
    }

    >div:first-child {
      display: flex;
      justify-content: space-around;
      padding-top: 2.1875rem;
      padding-bottom: 2.1875rem;
      border-bottom: 1px solid #aaa;

      >div {
        >p:first-child {
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 1.25rem;
        }
      }

      >div:first-child {
        >p:nth-child(2) {
          margin-bottom: 1.25rem;
          font-size: 0.8125rem;
          cursor: pointer;
          text-align: center;
        }

        >p:last-child {
          cursor: pointer;
          font-size: 0.8125rem;
          text-align: center;
        }

        >p:nth-child(2):hover {
          text-decoration: underline;
        }

        >p:nth-child(3):hover {
          text-decoration: underline;
        }
      }

      >div:nth-child(2) {
        >p:last-child {
          text-align: center;
          font-size: 0.8125rem;
          cursor: pointer;
        }

        >p:last-child:hover {
          text-decoration: underline;
        }
      }

      >div:nth-child(3) {
        >div {
          font-size: 0.8125rem;
          padding-left: 0.3125rem;

          >span {
            display: block;
            cursor: pointer;
          }

          >span:hover {
            text-decoration: underline;
          }

          >span:first-child {
            margin-bottom: 0.625rem;
          }
        }

        >div:nth-child(3) {
          margin: 1.875rem 0;
        }
      }

      >div:nth-child(4) {
        >p:nth-child(2) {
          font-size: 0.8125rem;
          text-align: center;
          margin-bottom: 1.25rem;
          cursor: pointer;
        }

        >p:nth-child(3) {
          font-size: 0.8125rem;
          text-align: center;
          cursor: pointer;
        }

        >p:nth-child(2):hover {
          text-decoration: underline;
        }

        >p:nth-child(3):hover {
          text-decoration: underline;
        }
      }
    }

    >div:last-child {
      padding: 1.875rem 0;

      >p {
        width: 52%;
        margin: auto;
        font-size: 0.8125rem;
        text-align: center;
        cursor: pointer;
      }

      >p:first-child {
        margin-bottom: 1.25rem;
      }

      >p:hover {
        text-decoration: underline;
      }

      >p:last-child {
        line-height: 1.5;
      }
    }
  }
}

// 移动
@media screen and (max-width: 768px) {
  .logo {
    >div {
      display: flex;

      >span {
        line-height: 3.125rem;
        margin-left: 0.625rem;
        font-weight: 600;
      }
    }
  }

  .menu {
    flex-direction: column;
    padding-top: 1.25rem;
  }

  .menu_content {
    padding: 1.25rem 0 0.625rem 0;
  }

  .content_6 {
    background-color: rgba(51, 51, 51, 1);

    >div {
      margin: auto;
      color: #fff;
    }

    >div:first-child {
      display: flex;
      flex-direction: column;
      padding: 2.1875rem 3%;
      border-bottom: 1px solid #aaa;

      >div {
        >p:first-child {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 1.25rem;
        }
      }

      >div:first-child {
        >p:nth-child(2) {
          margin-bottom: 1.25rem;
          font-size: 0.8125rem;
          text-align: center;
        }

        >p:last-child {
          margin-bottom: 1.25rem;
          font-size: 0.8125rem;
          text-align: center;
        }
      }

      >div:nth-child(2) {
        >p:last-child {
          text-align: center;
          font-size: 0.8125rem;
          margin-bottom: 1.25rem;
        }
      }

      >div:nth-child(3) {
        >div {
          font-size: 0.8125rem;
          text-align: center;

          >span {
            display: block;
          }

          >span:first-child {
            margin-bottom: 0.625rem;
          }
        }

        >div:nth-child(3) {
          margin: 1.875rem 0;
        }

        >div:last-child {
          margin-bottom: 1.875rem;
        }
      }

      >div:nth-child(4) {
        >p:nth-child(2) {
          font-size: 0.8125rem;
          text-align: center;
          margin-bottom: 1.25rem;
        }

        >p:nth-child(3) {
          font-size: 0.8125rem;
          text-align: center;
          margin-bottom: 1.875rem;
        }
      }
    }

    >div:last-child {
      padding: 1.875rem 0;

      >p {
        font-size: 0.8125rem;
        text-align: center;
      }

      >p:first-child {
        margin-bottom: 1.25rem;
      }

      >p:last-child {
        line-height: 1.5;
      }
    }
  }
}

// 设置怪异盒子防止页面宽度小于371px时布局乱掉
@media screen and (max-width: 371px) {
  .box {
    box-sizing: border-box;
    width: 23.1875rem;
  }

  .menu {
    box-sizing: border-box;
    width: 23.1875rem;
  }
}

// ..........................
.box {
  user-select: none;

  .index_1 {
    box-shadow: 2px 0px 31px 0px rgba(156, 156, 156, 0.29);
    //background-image: url("../assets/images/index_bg.svg");
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 1000;

    .menu {
      display: flex;

      >div:first-child {
        width: 50%;
      }

      img {
        display: block;
        width: 120px;
        // height: 50px;
        margin: 7px 0 0 2.5rem;
        // margin-left: 2.5rem;
        cursor: pointer;
      }

      >div:last-child {
        color: #333;
        display: flex;
        justify-content: space-around;
        font-size: 0.8125rem;

        span {
          a {
            color: #333;
            cursor: pointer;
          }

          line-height: 1.1875rem;
        }

        div {
          width: 4.75rem;
          height: 1.25rem;
          line-height: 1.1875rem;
          background-color: #EB5C20;
          text-align: center;
          border-radius: 0.25rem;
          cursor: pointer;
        }
      }
    }
  }
}</style>
