
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '/src/assets/styles/reset.css'


// 引入element ui组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// 引入axios
import axios from 'axios';
Vue.prototype.$axios = axios;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//配置默认的url请求路径，可以直接修改，子组件直接调用
// axios.defaults.baseURL='https://www.mhdsq.com'

// 添加请求拦截器 再请求头中加入token
axios.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token');
  }
  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

// 配置axios公用请求域名
axios.defaults.baseURL='https://api.mhdsq.com'




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

