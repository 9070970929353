import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/mxm/index',
    // home子路由
    children: [
      // 首页内容路由配置
      {
        path: '/mxm/index',
        name: 'Index',
        meta: {
          // 导航栏下滑线状态
          line_1: true
        },
        component: () => import('../views/homeChildren/Index.vue'),
      },
      // 解决方案路由配置
      {
        path: '/mxm/solution',
        name: 'Solution',
        meta: {
          // 导航栏下滑线状态
          line_2: true
        },
        component: () => import('../views/homeChildren/Solution.vue'),
      },
      // 商务合作路由配置
      {
        path: '/mxm/work',
        name: 'Work',
        meta: {
          // 导航栏下滑线状态
          line_3: true
        },
        component: () => import('../views/homeChildren/Work.vue'),
      },
      // 关于我们路由配置
      {
        path: '/mxm/aboutUs',
        name: 'AboutUs',
        meta: {
          // 导航栏下滑线状态
          line_4: true
        },
        component: () => import('../views/homeChildren/AboutUs.vue'),
      },

      //2023-02-19 应对京东演示，页面写死，放个图片
      //图片页面-供应链
      {
        path: '/mxm/demonstration',
        name: 'demonstration',
        meta: {
          // 导航栏下滑线状态
          line_3: true,
          img_name: 'demonstration',
        },
        component: () => import('../views/imgChildren/detail.vue'),
      },
    ]
  },
  // // 测试页面路由
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('../views/Test.vue'),
  // },
  // // 登录页路由配置
  // {
  //   path: '/mxm/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue'),
  // },
  // // 用户平台首页面路由配置
  // {
  //   path: '/mxm/userIndex',
  //   name: 'UserIndex',
  //   redirect: '/mxm/userIndex/indexContent',
  //   meta: {
  //     // 登录权限
  //     loginStatus: true
  //   },
  //   // userIndex子路由
  //   children: [
  //     // 用户平台首页路由内容
  //     {
  //       path: '/mxm/userIndex/indexContent',
  //       name: 'IndexContent',
  //       meta: {
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/IndexContent.vue'),
  //     },
  //     // 用户平台门店列表内容
  //     {
  //       path: '/mxm/userIndex/userStore',
  //       name: 'UserStore',
  //       meta: {
  //         breadcrumbName: ['门店列表'],
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/UserStore.vue'),
  //     },
  //     // 用户平台订单列表内容
  //     {
  //       path: '/mxm/userIndex/userOrder',
  //       name: 'UserOrder',
  //       meta: {
  //         breadcrumbName: ['订单列表'],
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/UserOrder.vue'),
  //     },
  //     // 用户公司信息
  //     {
  //       path: '/mxm/userIndex/userCompany',
  //       name: 'UserCompany',
  //       meta: {
  //         breadcrumbName: ['门店信息'],
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/UserCompany.vue'),
  //     },
  //     // 门店详情
  //     {
  //       path: '/mxm/userIndex/userStore/storeDetails',
  //       name: 'StoreDetails',
  //       meta: {
  //         breadcrumbName: ['门店信息', '门店详情'],
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/StoreDetails.vue'),
  //     },
  //     // 订单详情页
  //     {
  //       path: '/mxm/userIndex/userOrder/orderDetails',
  //       name: 'OrderDetails',

  //       meta: {
  //         breadcrumbName: ['订单列表', '订单详情'],
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/OrderDetails.vue'),
  //     },
  //     // 新增订单页
  //     {
  //       path: '/mxm/userIndex/userOrder/addUserOrder',
  //       name: 'AddUserOrder',
  //       meta: {
  //         breadcrumbName: ['订单列表', '新增订单'],
  //         // 登录权限
  //         loginStatus: true
  //       },
  //       component: () => import('../views/userChildren/AddUserOrder.vue'),
  //     },
      
  //   ],
  //   component: () => import('../views/UserIndex.vue'),
  // },

  // 404
  {
    path: '*',
    name: '404',
    component: () => import('../views/My404.vue'),
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 当刷新页面或者切换到新路由时，页面滚到顶部
  scrollBehavior(to, from, savedPosition) {
    // 期望滚动到哪个位置
    return {
      x: 0,
      y: 0
    }
  }
})


// 路由守卫
//使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登录
// router.beforeEach((to, from, next) => {
//   //console.log(to);
//   if (to.meta.loginStatus) {
//     //console.log(1);
//     let tokens = localStorage.getItem('token');
//     if (tokens === null || tokens === '') {
//       next('/mxm/login');
//     } else {
//       next()
//     }

//   } else {
//     next()
//   }

// })

export default router